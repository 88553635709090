$fontEn: 'Arial' !default;
$fontKh: 'Battambang' !default;

@font-face {
  font-family: $fontEn;
  font-weight: 400;
  src: url('../fonts/#{$fontEn}/#{$fontEn}-Regular.ttf') format('truetype');
  display: 'swap';
}
@font-face {
  font-family: $fontEn;
  font-weight: 700;
  src: url('../fonts/#{$fontEn}/#{$fontEn}-Bold.ttf') format('truetype');
  display: 'swap';
}

@font-face {
  font-family: $fontKh;
  font-weight: 400;
  src: url('../fonts/#{$fontKh}/#{$fontKh}-Regular.ttf') format('truetype');
  display: 'swap';
}
@font-face {
  font-family: $fontKh;
  font-weight: 600;
  src: url('../fonts/#{$fontKh}/#{$fontKh}-Bold.ttf') format('truetype');
  display: 'swap';
}
