@import '../assets/scss/fonts';

html,
body {
  font-family: 'Arial', 'Battambang', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
p,
th,
h1,
h2,
h3,
h4,
h5,
h6,
li,
div,
span,
select,
label,
input,
button {
  font-family: 'Arial', 'Battambang' !important;
}
